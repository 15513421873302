<template>
  <div class="container">
    <div class="searchBox">
      <div class="_SelectHeader">
        <div style="width: 690px">
          <span>专题学习：</span>
          <el-select style="width: 620px" v-model="theme" placeholder="请选择">
            <el-option
              v-for="item in themeList"
              :key="item.theme"
              :label="item.theme"
              :value="item.theme"
            >
            </el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="goThemesStudy">学习</el-button>
        <div class="assign" @click="$router.push('./assignStudy')">
          <img src="../../assets/images/study.png" alt />
          <span>指派专题</span>
        </div>
        <div class="assign" @click="toTest">
          <img src="../../assets/images/exam.png" alt />
          <span>模拟测试</span>
        </div>
      </div>
    </div>
    <div class="_TableBox learn">
      <el-table
        ref="multipleTable"
        :data="tableData.slice(start, end)"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
        height="500"
      >
        <el-table-column
          type="index"
          label="序号"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="interpretation"
          label="应知应会"
        ></el-table-column>
        <el-table-column prop="status" label="是否已学习" width="300">
          <!-- <template slot-scope="scope">
            <img
              v-if="scope.row.whether"
              src="../../assets/images/is.png"
              alt
            />
            <img v-else src="../../assets/images/no.png" alt />
          </template> -->
          <template slot-scope="scope">
            <div v-if="!AssignToThis">
              <el-button
                @click="goStudy(scope.row)"
                type="primary"
                v-if="scope.row.status == 0"
                >未学习</el-button
              >
              <span v-if="scope.row.status == 1" style="color: #388ff2"
                >已学习</span
              >
            </div>
            <div v-if="scope.row.status == 0 && AssignToThis">
              <el-button
                @click="goAssStudy(scope.row)"
                type="primary"
                v-if="scope.row.status == 0"
                >未学习</el-button
              >
              <span v-else style="color: #388ff2">已学习</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="curSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
      ></el-pagination>
    </div>
    <el-dialog
      title="抽题"
      :visible.sync="drawDialog"
      width="30%"
      top="25vh"
      class="draw-dialog"
    >
      <div class="inp">
        <span style="margin-right:10px">共 {{ this.count }} 道题，抽取题目数</span
        ><el-button
          class="btn"
          @click="handleDrawnNumber(0)"
          disabled
          icon="el-icon-minus"
        ></el-button
        ><el-input
          style="width: 80px;margin: 0 10px"
          disabled
          type="text"
          v-model="drawncount"
          @input="inputInp"
        /><el-button
          class="btn"
          @click="handleDrawnNumber(1)"
          disabled
          icon="el-icon-plus"
        ></el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="deawQuestion">确定</el-button>
        <el-button @click="drawDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put } from "../../api/http";

export default {
  data() {
    return {
      tableData: [],
      AssignToThis: null, //指派学习
      //  下拉框
      themeList: [],
      theme: "",
      // 抽题
      count: 0,
      drawncount: 0,
      drawDialog: false,
      // 分页
      start: 0,
      end: 10,
      currentPage: 1,
      curSize: 10,
      // 重新学习
      fileDialog: false,
      showFile: false,
      noCheckFileList: [],
      getNum: 0,
    };
  },
  watch: {
    $route(to, v) {
      if (v.path == "/assignStudy" && to.path == "/special-learn") {
        //special-subject未核对
        this.AssignToThis = true;
        this.theme = this.$route.query.theme;
        this.getAssignThemeStudyRecord(this.$route.query.AssId);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "Text" && from.name != "Score") {
      to.meta.ifDoFresh = true;
    }
    next();
  },
  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.$route.meta.ifDoFresh = false;
      this.theme = "";
      this.tableData = [];
      this.getThemeList();
      //指派第一次到这里是触发
      if (this.$route.query.AssId) {
        this.AssignToThis = true;
        this.getAssignThemeStudyRecord(this.$route.query.AssId);
      }
    }
  },
  mounted() {
    this.theme = "";
    this.tableData = [];
    this.getThemeList();
  },
  methods: {
    getThemeList() {
      get("/api/SettingTheme/GetThemeMonth").then((resp) => {
        if (resp.code == 200) {
          this.themeList = resp.data;
        }
      });
    },
    getAssignStudyRecord(e, v, t, m) {
      get(
        "/api/AssignStudyRecord/GetPostCount?CompId=" +
          e +
          "&DeptId=" +
          v +
          "&PostId=" +
          t
      ).then((res) => {
        this.count = res.count;
        this.drawncount = res.count;
        //this.drawDialog = true
        this.AssignDetail(e, v, t, m);
      });
    },
    AssignDetail(e, v, t, m) {
      get("api/AssignStudyRecord/GetByAssignId?AssignId=" + m).then((resp) => {
        if (resp.code == 200) {
          this.tableData = resp.data;
          this.drawDialog = false;
        } else {
          this.$message.error(resp.message);
        }
      });
    },
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    // 抽题
    goThemesStudy() {
      get(
        "/api/ThemesStudyList/" +
          JSON.parse(sessionStorage.getItem("userInfo")).userNo +
          "?themesName=" +
          this.theme
      ).then((res) => {
        if (res.code == 200) {
          if(!res.data.every(item => item.status)){
            this.$confirm("您还有未完成的学习题目，是否继续学习?")
              .then(() => {
                this.drawDialog = false;
                this.tableData = res.data;
              })
              .catch(() => {
                this.getCount();
              });
          }else{
            this.getCount();
          }

          // if (res.data.length != 0) {
          //   this.$confirm("您还有未完成的学习题目，是否继续学习?")
          //     .then(() => {
          //       this.drawDialog = false;
          //       this.tableData = res.data;
          //     })
          //     .catch(() => {
          //       this.getCount();
          //     });
          // } else {
          //   this.getCount();
          // }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getCount() {
      get("/api/ThemeStudyRecord/GetThemeCount?Theme=" + this.theme).then(
        (resp) => {
          if (resp.code == 200) {
            this.count = resp.count;
            this.drawncount = resp.count;
            this.drawDialog = true;
          } else {
            this.$message.error(resp.message);
          }
        }
      );
    },
    afreshStudy() {},
    deawQuestion() {
      post(
        "/api/ThemeStudyRecord/GetQuesByRandom?QuesNo=" +
          this.drawncount +
          "&Theme=" +
          this.theme
      )
        .then((resp) => {
          if (resp.code == 200) {
            if (
              resp.message == "您已经完成文件学习！如需重新学习，请点击学习"
            ) {
              this.fileDialog = true;
              return;
            }
            resp.data.map((item) => {
              item.lean = "未学习";
            });
            this.tableData = resp.data;
            this.drawDialog = false;
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("获取失败");
        });
    },
    handleDrawnNumber(i) {
      if (this.count == 0) {
        this.$message({
          type: "warning",
          message: "暂无抽题数",
        });
        return;
      }
      if (
        (this.count == 0 && i == 0) ||
        (this.drawncount == this.count && i == 1)
      ) {
        this.$message({
          type: "warning",
          message: "已超出范围",
        });
        return;
      }
      this.drawncount = i == 1 ? this.drawncount + 1 : this.drawncount - 1;
    },
    inputInp() {
      if (this.drawncount > this.count) this.drawncount = 0;
      if (this.drawncount < 0) this.drawncount = 0;
    },
    // 学习
    pushJsonItem(item, list, i) {
      item = {
        fileNo: i.fileNo,
        fileName: i.fileName,
      };
      if (list.length == 0) {
        list.push(JSON.stringify(item));
      } else {
        list = list + "," + JSON.stringify(item);
      }
    },
    goAssStudy(row) {
      put("/api/AssignThemeStudyRecord/" + row.id).then((res) => {
        if (res.code == "200") {
          row.status = 1;
        } else {
          this.$message({
            type: "warning",
            message: "失败",
          });
        }
      });
    },
    goStudy(row) {
      put("/api/ThemeStudyRecord/" + Number(row.id))
        .then((resp) => {
          if (resp.code == 200) {
            row.status = 1;
          } else {
            this.message.error(resp.message);
          }
        })
        .catch(() => {
          this.message.error("系统异常，请稍后再试");
        });
    },
    getAssignThemeStudyRecord(id) {
      get("/api/AssignThemeStudyRecord/GetByAssignId?AssignId=" + id).then(
        (res) => {
          if (res.data.length == 0) {
            if (this.getNum == 0) this.reGet(id);
          } else {
            this.tableData = res.data;
          }
        }
      );
    },
    reGet(id) {
      post("/api/AssignThemeStudyRecord/PostRandom?AssignId=" + id).then(() => {
        this.getNum += 1;
        this.getAssignThemeStudyRecord(id);
      });
    },
    handleReqData() {
      post("/api/ThemeStudyRecord/PostReStudy?Theme=" + this.theme)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "开始重新学习",
            });
            this.fileDialog = false;
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("重新学习失败");
        });
    },
    handleClose() {
      this.fileDialog = false;
    },
    toTest() {
      if (!this.theme) {
        this.$message({
          type: "warning",
          message: "未选择专题",
        });
        return;
      }
      this.$router.push("./test?queryIndex=3&theme=" + this.theme);
    },
  },
};
</script>

 <style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  .searchBox {
    ._SelectHeader {
      height: 30px;
      margin-top: 25px;
      .el-checkbox {
        margin-left: 14px;
        ::v-deep .el-checkbox__label {
          padding-left: 5px;
        }
      }
      .el-button {
        margin-right: 4px;
      }
      .assign {
        margin-left: 8px;
        // width: 63px;
        width: 70px;
        height: 60px;
        background: #f2f8ff;
        border: 1px solid #cdd2dc;
        border-radius: 8px;
        display: flex;
        align-self: flex-end;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 25px;
          height: 25px;
        }
        span {
          margin-top: 4px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #646870;
        }
      }
    }
  }
  /deep/ .learn tbody tr > td:nth-of-type(2) {
    text-align: start !important;
  }
  .dialogBox {
    display: flex;
    & > div:nth-child(1) {
      width: 60px;
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      margin-right: 16px;
      margin-top: 10px;
    }
    & > div:nth-child(2) {
      flex: 1;
      & > div:nth-child(1) {
        height: 40px;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(64, 65, 73, 0.18);
        display: flex;
        align-items: center;
        span {
          flex: 1;
          text-align: center;
        }
      }
      & > div:nth-child(2) {
        height: 40px;
        background: #f8f9fd;
        box-shadow: 0px 2px 4px 0px rgba(64, 65, 73, 0.18);
        display: flex;
        align-items: center;
        justify-content: space-around;
        span {
          flex: 1;
          text-align: center;
          .el-input {
            width: 84px;
            margin: 0 4px;
            ::v-deep .el-input__inner {
              width: 84px !important;
              height: 34px !important;
              border-radius: 0;
              text-align: center;
            }
          }
          img {
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>